import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { layout, container, regulations } from './label-presentation-page.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ILabelStylePresentation } from '../models/label-style.model';
import { TCommonTemplateData } from '../models/common-template-data.model';

import { getNodes } from '../utils/get-nodes';
import staticFiles from '../config/static-files';
import useQueryParam from '../hooks/use-query-param';

import MainLayout from '../layouts/main-layout';
import StyleDescription from '../components/organisms/style-description';
import StyleAccessories from '../components/organisms/style-accessories';
import StyleMoodBoard from '../components/organisms/style-moodboard';
import StyleSlider from '../components/organisms/style-slider';
import BottlePresentation from '../components/organisms/bottle-presentation';
import Regulations from '../components/molecules/regulations';
import LabelFloatingReminder from '../components/molecules/label-floating-reminder';

interface ILabelPresentationPage {
    readonly data: {
        allStrapiLabelStyle: IQueryAllResult<ILabelStylePresentation>;
    } & TCommonTemplateData;
}

const LabelPresentationPage: React.FC<ILabelPresentationPage> = ({ data }) => {
    const labelStyles = getNodes(data.allStrapiLabelStyle).sort((a, b) => a.rowOrder - b.rowOrder);
    const queryStyle = useQueryParam('styl');

    const found = labelStyles.find(
        (style) => style.type.toLocaleLowerCase() === queryStyle?.values[0]?.toLocaleLowerCase()
    );

    const initialStyle = found || labelStyles[0];

    const [activeStyle, setActiveStyle] = useState(initialStyle);
    const [initialSlide] = useState(labelStyles.indexOf(initialStyle));

    function loadStylePage(index: number) {
        queryStyle.setValue(labelStyles[index].type, true);
        setActiveStyle(labelStyles[index]);
    }

    let generatorUrl = '';

    switch (activeStyle.occasion) {
        case 'wedding':
            generatorUrl = '/soplica-weselna/generator';
            break;
        case 'birthday':
            generatorUrl = '/soplica-urodzinowa/generator';
            break;
        case 'anniversary':
            generatorUrl = '/soplica-rocznicowa/generator';
            break;
    }

    return (
        <MainLayout
            className={layout}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            page={data.page}
        >
            <div className={container}>
                <StyleSlider
                    name={activeStyle.type}
                    occasion={activeStyle.occasion}
                    generatorUrl={generatorUrl}
                    labelStyles={labelStyles}
                    initialSlide={initialSlide}
                    onLoadStylePage={loadStylePage}
                />
                <BottlePresentation
                    bottleType="lemon"
                    occasion={activeStyle.occasion}
                    labelStyle={activeStyle}
                />
                {activeStyle.occasion === 'wedding' && (
                    <BottlePresentation
                        bottleType="small"
                        occasion={activeStyle.occasion}
                        labelStyle={activeStyle}
                    />
                )}
                <StyleDescription
                    name={activeStyle.nameLocative || activeStyle.type}
                    type={activeStyle.type}
                    description={activeStyle.description}
                    subText={activeStyle.shortText}
                    imageData={activeStyle.sampleLabelImage}
                    backgroundImageData={activeStyle.bottleBackgroundAccentImage}
                />
                <StyleMoodBoard
                    name={activeStyle.type}
                    occasion={activeStyle.occasion}
                    generatorUrl={generatorUrl}
                    moodBoardImageData={activeStyle.moodBoardImage}
                    advices={activeStyle.advices}
                />
                {activeStyle.occasion === 'wedding' && (
                    <StyleAccessories
                        name={activeStyle.type}
                        accessoriesImageData={activeStyle.accessoriesImage}
                        accessoriesParagraph1={activeStyle.accessoriesText1}
                    />
                )}
                <Regulations
                    className={regulations}
                    regulationsUrl={staticFiles.labelRegulations}
                    translationKey={'label.summary.regulation'}
                />
                {activeStyle.occasion === 'wedding' && <LabelFloatingReminder />}
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!, $occasion: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allStrapiLabelStyle(filter: { occasion: { eq: $occasion }, locale: { eq: $language } }) {
            edges {
                node {
                    nameLocative
                    ...labelStylePresentationFields
                    ...labelSmallStyleBottleImageField
                    ...labelLemonStyleBottleImageField
                    ...labelStyleBottleImageFieldBig
                    ...labelStyleSampleImageField
                    rowOrder
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default LabelPresentationPage;
