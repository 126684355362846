import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    wrapper,
    contentWrapper,
    imageContainer,
    headerContainer,
    moodboardContent,
    moodboardButton,
} from './style-moodboard.module.scss';
import { grid, gridExtended } from '../../styles/grid.module.scss';

import { IMedia } from '../../models/media.model';

import StyleSubheader from '../atoms/style-subheader';
import StyleHeader from '../atoms/style-header';
import StyleParagraph from '../atoms/style-paragraph';
import Button from '../atoms/button';

interface IStyleMoodBoard {
    name: string;
    occasion: string;
    generatorUrl: string;
    advices: string;
    moodBoardImageData: IMedia;
}

const StyleMoodBoard: React.FC<IStyleMoodBoard> = ({
    name,
    advices,
    moodBoardImageData,
    occasion,
    generatorUrl,
}) => {
    const { t } = useI18next();
    const moodBoardImageGatsbyData = getImage(moodBoardImageData.localFile);

    const params = [
        {
            key: 'styl',
            value: name,
        },
    ];

    return (
        <div className={`${grid} ${gridExtended} ${layout} layout-${occasion}`}>
            <div className={wrapper}>
                <div className={contentWrapper}>
                    <div className={moodboardContent}>
                        {occasion === 'wedding' && (
                            <div className={headerContainer}>
                                <StyleSubheader text={t('style.moodBoard.wedding.subheader')} />
                                <StyleHeader text={t('style.moodBoard.wedding.header')} />
                            </div>
                        )}
                        {occasion === 'birthday' && (
                            <div className={headerContainer}>
                                <StyleHeader text={t('style.moodBoard.birthday.header')} />
                            </div>
                        )}
                        {occasion === 'anniversary' && (
                            <div className={headerContainer}>
                                <StyleHeader text={t('style.moodBoard.anniversary.header')} />
                            </div>
                        )}
                        <StyleParagraph>{advices}</StyleParagraph>
                    </div>

                    {occasion === 'wedding' ? (
                        <div className={imageContainer}>
                            {moodBoardImageGatsbyData && (
                                <GatsbyImage
                                    image={moodBoardImageGatsbyData}
                                    alt={moodBoardImageData.alternativeText}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={imageContainer}>
                            {moodBoardImageGatsbyData && (
                                <GatsbyImage
                                    image={moodBoardImageGatsbyData}
                                    alt={moodBoardImageData.alternativeText}
                                />
                            )}
                        </div>
                    )}
                </div>
                <Button className={moodboardButton} to={generatorUrl} params={params} as={'link'}>
                    {t('style.page.link.to.creator')}
                </Button>
            </div>
        </div>
    );
};

export default StyleMoodBoard;
