import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    wrapper,
    imagesContainer,
    image,
    imageRatio,
    backgroundImage,
    backgroundImageRatio,
    contentContainer,
    headerContainer,
    subTextClass,
    smaller,
    higher,
} from './style-description.module.scss';
import { grid, gridExtended } from '../../styles/grid.module.scss';

import { IMedia } from '../../models/media.model';

import StyleSubheader from '../atoms/style-subheader';
import StyleHeader from '../atoms/style-header';
import StyleParagraph from '../atoms/style-paragraph';
import RatioImage from '../atoms/ratio-image';

interface IStyleDescription {
    name: string;
    description: string;
    subText: string;
    imageData: IMedia;
    backgroundImageData: IMedia;
    type: string;
}

const StyleDescription: React.FC<IStyleDescription> = ({
    name,
    description,
    subText,
    imageData,
    backgroundImageData,
    type,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${grid} ${gridExtended} ${layout}`}>
            <div className={wrapper}>
                <div className={imagesContainer}>
                    <RatioImage
                        className={image}
                        ratioClass={imageRatio}
                        image={getImage(imageData.localFile)}
                        alt={imageData.alternativeText}
                        objectFit={'contain'}
                    />
                    {backgroundImageData && (
                        <RatioImage
                            className={`${backgroundImage} ${type !== 'Klasyczny' ? smaller : ''} ${
                                type === 'Folk' ? higher : ''
                            }`}
                            ratioClass={backgroundImageRatio}
                            image={getImage(backgroundImageData.localFile)}
                            alt={backgroundImageData.alternativeText}
                            objectFit={'contain'}
                        />
                    )}
                </div>
                <div className={contentContainer}>
                    <div className={headerContainer}>
                        <StyleSubheader text={t('style.page.description.subheader')} />
                        <StyleHeader text={name} />
                        <StyleSubheader text={subText} className={subTextClass} />
                    </div>
                    <StyleParagraph>{description}</StyleParagraph>
                </div>
            </div>
        </div>
    );
};
export default StyleDescription;
